import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authorizedGuard } from '@core/guards/authorized.guard';
import { notAuthorizedGuard } from '@core/guards/not-authorized.guard';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { UnauthorizedAccessComponent } from '@shared/components/unauthorized-access/unauthorized-access.component';
import { SecureLayoutComponent } from '@shared/modules/layout/secure-layout.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [ notAuthorizedGuard ],
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: '',
    component: SecureLayoutComponent,
    canActivateChild: [ authorizedGuard ],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)
      }
    ]
  },
  { path: 'unauthorized', component: UnauthorizedAccessComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
