<div class="header-content d-flex align-items-center justify-content-between">
<!--  <button nz-button nzType="text" (click)="handleToggle()">-->
<!--    <span nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>-->
<!--  </button>-->
  <nz-breadcrumb [nzAutoGenerate]="true"></nz-breadcrumb>
  <div class="d-flex align-items-center justify-content-between">
    <nz-space nzSize="middle">
      <button nz-button nzType="text" nzSize="large" class="user-menu py-0"
              nz-dropdown [nzDropdownMenu]="menu"
              [nzTrigger]="'click'" *nzSpaceItem>
        <nz-space nzAlign="center">
          <span class="font-weight-bold fs-6 user-name me-1" *nzSpaceItem>{{ (profile$ | async)?.fullName || '&nbsp;' }}</span>
          <span class="user-icon rounded-circle border p-3" nz-icon nzType="user" nzTheme="outline" *nzSpaceItem></span>
          <svg-icon [src]="'assets/icons/arrow-down.svg'" [svgClass]="'arrow-down'" *nzSpaceItem></svg-icon>
        </nz-space>
      </button>
      <div *nzSpaceItem>
        <svg-icon [src]="'assets/icons/bell.svg'" [svgClass]="'bell-icon'"></svg-icon>
      </div>
    </nz-space>
  </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu nzSize="large" class="py-3">
    <li nz-menu-item [routerLink]="['/', ROUTE.profile]" class="py-2 px-4">
      <span class="pe-2" nz-icon nzType="user" nzTheme="outline"></span> Profile
    </li>
    <li nz-menu-item (click)="confirmLogout()" class="py-2 px-4">
      <span class="pe-2" nz-icon nzType="logout" nzTheme="outline"></span> Logout
    </li>
  </ul>
</nz-dropdown-menu>
