import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
class AuthorizedGuardService {
  constructor(private authService: AuthenticationService,
              private router: Router) { }

  canActivate(): Observable<boolean> | boolean {
    if (this.authService.hasToken()) {

      // validate if logged in user belong to Back Office group
      return this.authService.getMe()
        .pipe(map(user => {
            const isBackOfficeUser = (user?.principal?.claims?.groups || []).find(v => v.includes('Back Office'));
            if (isBackOfficeUser) {
              return true;
            } else {
              this.router.navigateByUrl(`/unauthorized`);
              return false;
            }
          }),
          catchError(() => {
            return of(true);
          }));
    }

    this.router.navigateByUrl(`/login`);
    return false;
  }

}

export const authorizedGuard: CanActivateFn = () => {
  return inject(AuthorizedGuardService).canActivate();
};
