<nz-layout class="layout">
  <nz-sider nzTheme="dark" [nzWidth]="288" [nzCollapsedWidth]="120" nzBreakpoint="xl"
            nzCollapsible [(nzCollapsed)]="isCollapsed">
    <div class="logo">
      <img
        class="img-fluid c-pointer"
        [src]="isCollapsed ? 'assets/brand/shlonak-icon.png' : 'assets/brand/shlonak-light.png'"
        routerLink="/"
        alt="" />
    </div>
    <div class="scroll-container">
      <app-menu [isCollapsed]="isCollapsed"></app-menu>
    </div>
  </nz-sider>
  <nz-layout [ngClass]="isCollapsed ? 'right-layout-closed' : 'right-layout'">
    <nz-header [ngClass]="{'collapsed': isCollapsed}">
      <app-header [isCollapsed]="isCollapsed" (showHide)="showHide($event)"></app-header>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
