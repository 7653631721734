<ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
  <ng-container *ngFor="let menu of menuItems">
    <ng-container *ngIf="menu.children.length === 0; else subMenu">
      <li
        #parentMenuState="routerLinkActive" routerLinkActive
        nz-menu-item
        [routerLink]="['/', menu.route]"
        nzMatchRouter
        [ngClass]="isCollapsed ? '' : 'open-menu'">
        <a class="d-flex align-items-center text-decoration-none">
          <svg-icon [src]="'assets/icons/' + ( parentMenuState.isActive ? menu.icon + '_filled' : menu.icon ) + '.svg'"></svg-icon>
          <span class="text-truncate parent">{{ isCollapsed ? '' : menu.label }}</span>
        </a>
      </li>
    </ng-container>
    <ng-template #subMenu>
      <li
        nz-submenu
        [nzTitle]="parentMenuTitle"
        [nzOpen]="activeRoute === menu.route"
        [ngClass]="{'ant-menu-submenu-selected': childRouterState.isActive, 'open-menu': !isCollapsed}"
        (nzOpenChange)="activeRoute = menu.route; activeChildRoute = null">
        <ng-template #parentMenuTitle>
          <svg-icon [src]="'assets/icons/' + menu.icon"></svg-icon>
          <span class="text-truncate parent">{{ isCollapsed ? '' : menu.label }}</span>
        </ng-template>
        <ul #childRouterState="routerLinkActive" routerLinkActive>
          <ng-container *ngFor="let child of menu.children">
            <ng-container *ngIf="!child.children || child.children?.length === 0">
              <li
                nz-menu-item
                [routerLink]="child.route"
                nzMatchRouter>
                <span class="text-truncate child">{{ child.label }}</span>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </li>
    </ng-template>
  </ng-container>
</ul>

