import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { ACTIONS, MAIN_MODULE_ROUTES, MESSAGES } from '@shared/constants';
import { CurrentUser } from '@shared/models';
import { NzModalService } from 'ng-zorro-antd/modal';
import { finalize, Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  @Input() isCollapsed!: boolean;
  @Output() showHide = new EventEmitter<boolean>();

  ROUTE = MAIN_MODULE_ROUTES;
  profile$: Observable<CurrentUser>;

  constructor(private auth: AuthenticationService,
              private ms: NzModalService,
              private r: Router) {

    this.profile$ = this.auth.getMe();

  }

  confirmLogout(): void {
    this.ms.confirm({
      nzTitle: MESSAGES.confirmLogout,
      nzOkText: ACTIONS.yes,
      nzCancelText: ACTIONS.no,
      nzAutofocus: 'ok',
      nzOnOk: () => this.logout()
    });
  }

  private logout(): void {
    this.auth.logout()
      .pipe(finalize(() => this.r.navigateByUrl('/login'))).subscribe();
  }

  handleToggle(): void {
    this.showHide.emit(!this.isCollapsed);
  }

}
