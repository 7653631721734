import { Component } from '@angular/core';

@Component({
  selector: 'app-secure-layout',
  templateUrl: './secure-layout.component.html',
  styleUrl: './secure-layout.component.scss'
})
export class SecureLayoutComponent {

  isCollapsed = false;

  showHide(isCollapsed: boolean): void {
    this.isCollapsed = isCollapsed;
  }

}
